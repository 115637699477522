<template>
    <div class="content-box">
        <div class="title">{{ brandName }}</div>
        <div class="action-box">
            <div class="action-item" v-for="(item, index) in actionList" :key="index" @click="goAction(item.path)">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script>
import { Mixins, Component, Watch } from "vue-property-decorator";
import loading from "@/mixins/loading";
@Component
export default class actionList extends loading {

    merchantId = this.$route.query.merchantId || "";;
    brandName = this.$route.query.brandName || "";
    actionList = [
        { name: "编辑", path: "/merchantEdit" },
        { name: "分类配置", path: "/prescription-product-merchant-category" },
        { name: "产品管理", path: "/prescription-product-merchant-list" },
        { name: "处方库", path: "/merchantDb" },
        { name: "中医大脑排版", path: "/merchantAppCompose" },
        { name: "小程序Banner", path: "/merchantBanner" },
        { name: "小程序配置", path: "/merchantMiniSetting" },
    ]

    goAction(path) {
        this.$router.push({
        path,
        query: { merchantId: this.merchantId, brandName: this.brandName }
        })
    }
}
</script>
<style lang="scss" scoped>
.content-box {
    background: #ffffff;
    padding: 25px;

    .title {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
    }

    .action-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;

        .action-item {
            display: inline-block;
            width: 18%;
            min-width: 120px;
            height: 150px;
            margin-right: 25px;
            margin-bottom: 25px;
            background: #f2f2f2;
            line-height: 150px;
            text-align: center;
            font-size: 15px;
            cursor: pointer;
        }
    }
}

</style>